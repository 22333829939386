<script>
import { SuawSection, SuawInputGroup, SuawHeading, SuawParagraph, SuawEmptyState, SuawCover, SuawButton, SuawTextInput } from "@suaw/suaw-component-library";
import { GetSeriesViewForAdminBasic } from "../../operations.gql";
import * as SeriesApi from "../../api.js";
import { formattedTime } from "@/utils/formatting/dates.js";
import { SeriesScheduleFromICalText } from "../../common.js";

export default {
  name: "SeriesViewBasic",
  components: {
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    SuawParagraph,
    SuawEmptyState,
    SuawCover,
    SuawButton,
    SuawTextInput
  },
  props: {
    seriesId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      //Basic
      loadingQueriesCount: 0,
      resultGetSeriesViewForAdminBasic: {
        id: "",
        created_at: "",
        description_json: {},
        duration: "",
        ical_text: "",
        is_cancelled: false,
        is_draft: false,
        is_featured: false,
        is_global: false,
        is_virtual: false,
        photo_url: "",
        place: {
          city: "",
          country_abbrev: "",
          lat: "",
          lng: "",
          postal_code: "",
          region: "",
          street_address: "",
          timezone: "",
          title: ""
        },
        chapter: {
          id: "",
          slug: "",
          meetup_chapter_map: {
            id: ""
          }
        },
        qr_code_url: "",
        rsvp_limit: 0,
        slug: "",
        title: "",
        venue_json: {},
        meetup_event_series_map: {
          id: "",
          meetup_physical_image_id: "",
          meetup_virtual_image_id: "",
          active_at: null
        }
      },
      //Meetup
      meetupPhysicalImageId: "",
      meetupVirtualImageId: "",
      isEditingMeetup: false
    };
  },
  apollo: {
    resultGetSeriesViewForAdminBasic: {
      query: GetSeriesViewForAdminBasic,
      variables() {
        return {
          id: this.seriesId
        };
      },
      skip() {
        return !this.seriesId;
      },
      loadingKey: "loadingQueriesCount"
    }
  },
  computed: {
    //Basic Info
    isLoading() {
      return this.loadingQueriesCount > 0;
    },
    hasData() {
      return this.resultGetSeriesViewForAdminBasic.id !== "";
    },
    hasMeetupChapter() {
      return !!this.resultGetSeriesViewForAdminBasic.chapter.meetup_chapter_map;
    },
    meetupPhysicalImage() {
      return this.resultGetSeriesViewForAdminBasic.meetup_event_series_map?.meetup_physical_image_id;
    },
    meetupVirtualImage() {
      return this.resultGetSeriesViewForAdminBasic.meetup_event_series_map?.meetup_virtual_image_id;
    },
    canExportToMeetup() {
      return this.meetupPhysicalImage && this.meetupVirtualImage;
    },
    meetupIsExporting() {
      return !!this.resultGetSeriesViewForAdminBasic.meetup_event_series_map?.active_at;
    },
    createdAt() {
      return this.formatDateAt(this.resultGetSeriesViewForAdminBasic.created_at);
    },
    seriesLink() {
      const route = this.$router.resolve({
        name: "SeriesOnly",
        params: {
          seriesId: this.resultGetSeriesViewForAdminBasic.id,
          chapterSlug: this.resultGetSeriesViewForAdminBasic.chapter.slug
        }
      });
      return `${window.location.origin}${route.href}`;
    },
    seriesSchedule() {
      return SeriesScheduleFromICalText(this.resultGetSeriesViewForAdminBasic.ical_text, this.resultGetSeriesViewForAdminBasic.duration);
    },
    chipContent() {
      return `${this.seriesSchedule.weeklyFrequencyText} on ${this.seriesSchedule.byDay}`;
    },
    hasQrCode() {
      return !!this.resultGetSeriesViewForAdminBasic.qr_code_url;
    }
  },
  methods: {
    formatDateAt(dstr) {
      const d = new Date(dstr);
      return `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()} ${formattedTime(d)}`;
    },
    async generateQrCode(seriesId) {
      const result = await SeriesApi.generateQRCode(seriesId);
      return result;
    },
    async onGenerateQrCodeClick() {
      let result = await this.generateQrCode(this.resultGetSeriesViewForAdminBasic.id);
      if (result.success) {
        await this.$apollo.queries.resultGetSeriesViewForAdminBasic.refetch();
        this.$root.$emit("universal-success-message", "QR code generated");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    async featureEventSeries(seriesId) {
      const result = await SeriesApi.featureEventSeries(seriesId);
      return result;
    },
    async onFeatureClick() {
      let result = await this.featureEventSeries(this.resultGetSeriesViewForAdminBasic.id);
      if (result.success) {
        await this.$apollo.queries.resultGetSeriesViewForAdminBasic.refetch();
        this.$root.$emit("universal-success-message", "Event Series Featured");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    async unfeatureEventSeries(seriesId) {
      const result = await SeriesApi.unfeatureEventSeries(seriesId);
      return result;
    },
    async onUnfeatureClick() {
      let result = await this.unfeatureEventSeries(this.resultGetSeriesViewForAdminBasic.id);
      if (result.success) {
        await this.$apollo.queries.resultGetSeriesViewForAdminBasic.refetch();
        this.$root.$emit("universal-success-message", "Event Series Unfeatured");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    onEditMeetupClick() {
      this.isEditingMeetup = true;
      this.meetupPhysicalImageId = this.meetupPhysicalImage;
      this.meetupVirtualImageId = this.meetupVirtualImage;
    },
    onCancelMeetupClick() {
      this.isEditingMeetup = false;
    },
    async mapMeetupEventSeries(seriesId, meetupPhysicalImageId, meetupVirtualImageId) {
      const result = await SeriesApi.mapMeetupEventSeries(seriesId, meetupPhysicalImageId, meetupVirtualImageId);
      return result;
    },
    async onSaveMeetupClick() {
      let result = await this.mapMeetupEventSeries(this.resultGetSeriesViewForAdminBasic.id, this.meetupPhysicalImageId, this.meetupVirtualImageId);
      if (result.success) {
        this.isEditingMeetup = false;
        await this.$apollo.queries.resultGetSeriesViewForAdminBasic.refetch();
        this.$root.$emit("universal-success-message", "Meetup Details Saved");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    async onActivateExportClick() {
      const result = await SeriesApi.activateMeetupEventSeriesExport(this.resultGetSeriesViewForAdminBasic.id);
      if (result.success) {
        await this.$apollo.queries.resultGetSeriesViewForAdminBasic.refetch();
        this.$root.$emit("universal-success-message", "Meetup Export Activated");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    },
    async onDeactivateExportClick() {
      const result = await SeriesApi.deactivateMeetupEventSeriesExport(this.resultGetSeriesViewForAdminBasic.id);
      if (result.success) {
        await this.$apollo.queries.resultGetSeriesViewForAdminBasic.refetch();
        this.$root.$emit("universal-success-message", "Meetup Export Deactivated");
      } else {
        this.$root.$emit("universal-error-message", result.error);
      }
    }
  }
};
</script>

<template>
  <SuawEmptyState v-if="isLoading && !hasData" message="Loading..." />
  <SuawInputGroup v-else group-style="default" direction="column">
    <SuawSection>
      <SuawHeading content="Cover Photo" level="5" />
    </SuawSection>
    <SuawSection>
      <SuawCover :cover-image="resultGetSeriesViewForAdminBasic.photo_url" :heading="resultGetSeriesViewForAdminBasic.title" :chip-content="chipContent" />
    </SuawSection>
    <SuawSection>
      <SuawHeading content="Details" level="5" />
    </SuawSection>
    <table>
      <tr>
        <td><strong>Series Detail Link</strong></td>
        <td>
          <a :href="seriesLink">{{ seriesLink }}</a>
        </td>
      </tr>
      <tr>
        <td><strong>Created At</strong></td>
        <td>{{ createdAt }}</td>
      </tr>
      <tr>
        <td><strong>Title</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.title }}</td>
      </tr>
      <tr>
        <td><strong>Slug</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.slug }}</td>
      </tr>
      <tr>
        <td><strong>Duration</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.duration }}</td>
      </tr>
      <tr>
        <td><strong>Cancelled?</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.is_cancelled }}</td>
      </tr>
      <tr>
        <td><strong>Draft?</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.is_draft }}</td>
      </tr>
      <tr>
        <td><strong>Featured?</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.is_featured }}
          <SuawButton
            v-if="!resultGetSeriesViewForAdminBasic.is_featured"
            button-text="Feature"
            type="secondary-outline"
            class="suaw-button--pull-left"
            size="small"
            @click="onFeatureClick"
          ></SuawButton>
          <SuawButton
            v-if="resultGetSeriesViewForAdminBasic.is_featured"
            button-text="Unfeature"
            type="secondary-outline"
            class="suaw-button--pull-left"
            size="small"
            @click="onUnfeatureClick"
          ></SuawButton>
        </td>
      </tr>
      <tr>
        <td><strong>Global?</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.is_global }}</td>
      </tr>
      <tr>
        <td><strong>Virtual?</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.is_virtual }}</td>
      </tr>
      <tr>
        <td><strong>QR Code Url</strong></td>
        <td v-if="hasQrCode">
          <a :href="resultGetSeriesViewForAdminBasic.qr_code_url" target="_blank">{{ resultGetSeriesViewForAdminBasic.qr_code_url }}</a>
        </td>
        <td v-else>
          <SuawButton
            button-text="Generate Missing QR Code"
            type="critical"
            class="suaw-button--pull-left"
            size="small"
            @click="onGenerateQrCodeClick"
          ></SuawButton>
        </td>
      </tr>
      <tr>
        <td><strong>RSVP Limit</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.rsvp_limit }}</td>
      </tr>
      <tr>
        <td><strong>Street Address</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.street_address }}</td>
      </tr>
      <tr>
        <td><strong>City</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.city }}</td>
      </tr>
      <tr>
        <td><strong>Region</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.region }}</td>
      </tr>
      <tr>
        <td><strong>Postal Code</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.postal_code }}</td>
      </tr>
      <tr>
        <td><strong>Country</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.country_abbrev }}</td>
      </tr>
      <tr>
        <td><strong>Timezone</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.timezone }}</td>
      </tr>
      <tr>
        <td><strong>Latitude</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.lat }}</td>
      </tr>
      <tr>
        <td><strong>Longitude</strong></td>
        <td>{{ resultGetSeriesViewForAdminBasic.place.lng }}</td>
      </tr>
    </table>
    <SuawInputGroup v-if="hasMeetupChapter" group-style="default" direction="column">
      <SuawHeading content="Meetup" level="5" />
      <SuawButton
        v-if="!isEditingMeetup"
        button-text="Edit Meetup Details to Export to Meetup"
        type="secondary-outline"
        class="suaw-button--pull-left"
        size="small"
        @click="onEditMeetupClick"
      ></SuawButton>
      <SuawButton
        v-else
        button-text="Cancel Meetup Detail Edit"
        type="critical-light"
        class="suaw-button--pull-left"
        size="small"
        @click="onCancelMeetupClick"
      ></SuawButton>
      <table>
        <tr>
          <td><strong>Meetup Image ID (Physical)</strong></td>
          <td>{{ meetupPhysicalImage }}</td>
          <td v-if="isEditingMeetup">
            <SuawTextInput v-model="meetupPhysicalImageId" type="text" />
          </td>
        </tr>
        <tr>
          <td><strong>Meetup Image ID (Virtual)</strong></td>
          <td>{{ meetupVirtualImage }}</td>
          <td v-if="isEditingMeetup">
            <SuawTextInput v-model="meetupVirtualImageId" type="text" />
          </td>
        </tr>
      </table>
      <SuawButton
        v-if="isEditingMeetup"
        button-text="Save Meetup Details"
        type="primary-light"
        class="suaw-button--pull-left"
        size="small"
        @click="onSaveMeetupClick"
      ></SuawButton>
      <SuawButton
        v-if="!isEditingMeetup && !meetupIsExporting && canExportToMeetup"
        button-text="Start Exporting to Meetup"
        type="primary-light"
        class="suaw-button--pull-left"
        size="small"
        @click="onActivateExportClick"
      ></SuawButton>
      <SuawButton
        v-if="!isEditingMeetup && meetupIsExporting"
        button-text="Stop Exporting to Meetup"
        type="critical-light"
        class="suaw-button--pull-left"
        size="small"
        @click="onDeactivateExportClick"
      ></SuawButton>
      <SuawHeading content="About" level="5" />
      <SuawSection section-style="border">
        <SuawParagraph :text="resultGetSeriesViewForAdminBasic.description_json" />
      </SuawSection>
    </SuawInputGroup>
  </SuawInputGroup>
</template>

<style lang="css" scoped>
</style>
