<script>
import { SuawMainContent, SuawSection, SuawInputGroup, SuawHeading } from "@suaw/suaw-component-library";
import SeriesViewBasic from "../components/SeriesViewBasic.vue";

export default {
  name: "SeriesView",
  components: {
    SuawMainContent,
    SuawSection,
    SuawInputGroup,
    SuawHeading,
    SeriesViewBasic
  },
  computed: {
    seriesId() {
      return this.$route.params.seriesId;
    }
  }
};
</script>

<template>
  <SuawMainContent>
    <SuawSection section-size="medium">
      <SuawInputGroup direction="column">
        <SuawHeading content="Series Admin" level="3" />
        <SeriesViewBasic :series-id="seriesId" />
      </SuawInputGroup>
    </SuawSection>
  </SuawMainContent>
</template>

<style lang="css" scoped></style>
